import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  makeStyles,
  Button,
  TextField
} from '@material-ui/core';
import { isEmpty } from 'lodash';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  API_BASE_URL,
  APP_BASE_URL,
  AWAKE_BASE_URL
} from '../../../../config/env';
import { CURRENCY_SYMBOL } from '../../../../constants';
import {
  binaryToAscii,
  convertNegativeCurrency,
  getApiResponseObject,
  getAuthToken,
  getUserType,
  postWithResponseObject,
  showApiErrorToast,
  successStatusCodes
} from '../../../../utils';
import { toaster } from '../../../atoms/toaster';
import ConfirmationDialog from '../../NetworkOperator/Approvals/dialogs/confirmationDialog';
import * as actions from '../../../../redux/actions/uiActions';
import EmptyState from '../../../atoms/EmptyState';

const styles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset'
    }
  },
  tableBody: {
    opacity: '1',
    transition: 'all .1s linear'
  },
  connectedColor: {
    color: '#43C009'
  },
  pendingColor: {
    color: '#EF1002'
  },
  actionBtn: {
    padding: '5px 8px',
    textTransform: 'none',
    minWidth: '80px',
    color: '#303030',
    backgroundColor: '#F0D64E',
    '&:hover': {
      backgroundColor: '#ebc918'
    }
  },
  zeroState: {
    textAlign: 'center',
    fontSize: '16px',
    marginTop: 10,
    height: '200px'
  },
  descText: {
    fontSize: '12px',
    fontWeight: '600',
    marginBottom: '20px'
  },
  textAlign: {
    textAlign: 'center'
  },
  textValue: {
    fontSize: '14px',
    fontWeight: '600',
    color: 'black'
  },
  textKey: {
    fontSize: '14px',
    color: 'black',
    width: '120px',
    textAlign: 'initial'
  },
  transactionRow: {
    justifyContent: 'flex-start',
    display: 'flex',
    marginLeft: '20px',
    color: 'black'
  }
});
function NetworkTable(props) {
  const classes = styles();
  const [confirmationDialog, setConfirmationDialog] = useState({
    open: false,
    title: 'Set Withdraw Amount',
    description: '',
    selectedData: {},
    enteredAmount: ''
  });
  const [transactionSuccess, setTransactionSuccess] = useState({
    open: false,
    title: 'Withdrawal Confirmation Details',
    description: ''
  });
  const dispatch = useDispatch();

  const handleAction = async data => {
    console.log(data);
    if ((data.token && data.token.details_submitted == true)) {
      console.log('Stripe KYC is complete');
      setConfirmationDialog(prevState => {
        return {
          ...prevState,
          open: true,
          description: renderDescription(),
          selectedData: data
        };
      });
    } else {
      console.log('Stripe KYC is pending');
      let response = '';
      const token = getAuthToken();
      const requestBody = {
        redirect_url: `${APP_BASE_URL}/wallet/stripe-connect/confirm`,
        user_id: data.linked_user_id,
        currency: data.asset_type_unit
      };
      const headers = { authorization: token };

      response = await postWithResponseObject(
        `${API_BASE_URL}/awake-money/linked-accounts`,
        requestBody,
        headers
      );

      if (successStatusCodes.includes(response.status)) {
        const url = new URL(response.data.slug);
        const state = {
          user_id: data.linked_user_id,
          account_id: data.linked_account_id,
          currency: data.asset_type_unit,
          tab: getUserType()
        };
        url.searchParams.delete('state');
        const stateEncoded = binaryToAscii(state);
        url.searchParams.set('state', stateEncoded);
        url.searchParams.sort((a, b) => (a > b ? -1 : 1));

        localStorage.setItem('accountId', data.linked_account_id);

        // console.log(this.props);
        // localStorage.setItem('tab', this.props.tab);
        // localStorage.setItem('connectionId', connectionObjId);

        window.location.assign(decodeURIComponent(url.href));
      } else {
        showApiErrorToast(response.data);
      }
    }
  };
  const handleRazorPayAction = async ele => {
    const token = getAuthToken();
    const headers = { authorization: token };

    const response = await getApiResponseObject(
      `${API_BASE_URL}/awake-money/redirectSlug`,
      headers
    );

    if (successStatusCodes.includes(response.status)) {
      window.open(
        `${AWAKE_BASE_URL}/validate${response.data.redirectSlug}&tab=withdraw&currency=${ele.asset_type_unit}`,
        '_blank'
      );
    } else {
      toaster('Cannot generate token');
    }
  };
  const handleConfirmAction = async () => {
    if (
      confirmationDialog.enteredAmount > confirmationDialog.selectedData.amount
    ) {
      return toaster('Please enter an amount available in your wallet');
    } else {
      let response = '';
      const token = getAuthToken();
      const requestBody = {
        withdraw_params: {
          amount: parseFloat(confirmationDialog.enteredAmount),
          currency: confirmationDialog.selectedData.asset_type_unit
        },
        asset_type_id: confirmationDialog.selectedData.asset_type_id,
        payment_method_type: confirmationDialog.selectedData.type,
        linked_account_id: confirmationDialog.selectedData.linked_account_id
      };
      const headers = { authorization: token };
      dispatch(actions.showSpinnerBackdropAction());

      response = await postWithResponseObject(
        `${API_BASE_URL}/awake-money/linked-accounts/withdraw`,
        requestBody,
        headers
      );
      setConfirmationDialog(prevState => {
        return {
          ...prevState,
          open: false
        };
      });
      if (successStatusCodes.includes(response.status)) {
        setTransactionSuccess(prevState => {
          return {
            ...prevState,
            open: true,
            description: renderTransactionDescription(response.data)
          };
        });
      } else {
        showApiErrorToast(response.data);
      }
      dispatch(actions.hideSpinnerBackdropAction());
    }
  };

  const renderDescription = () => {
    return (
      <TextField
        type="number"
        variant="outlined"
        label="Amount"
        InputProps={{ inputProps: { min: 0 } }}
        onChange={e => handleAmountChange(e)}
      />
    );
  };

  const renderTransactionDescription = data => {
    return (
      <div className={classes.textAlign}>
        <p className={classes.descText}>
          Kindly copy the below details for future reference
        </p>
        <div className={classes.transactionRow}>
          <span className={classes.textKey}>Amount: </span>{' '}
          <span className={classes.textValue}>
            {confirmationDialog.enteredAmount}
          </span>
        </div>
        <div className={classes.transactionRow}>
          <span className={classes.textKey}>Currency: </span>{' '}
          <span className={classes.textValue}>
            {confirmationDialog.selectedData.asset_type_unit.toUpperCase()}
          </span>
        </div>
        <div className={classes.transactionRow}>
          <span className={classes.textKey}>Transaction Id: </span>{' '}
          <span className={classes.textValue}>{data.id}</span>
        </div>
      </div>
    );
  };

  const handleSuccessPopup = () => {
    setTransactionSuccess(prevState => {
      return {
        ...prevState,
        open: false
      };
    });
    props.updateLinkedData();
  };

  const closeConfirmationDialog = () => {
    setConfirmationDialog(prevState => {
      return {
        ...prevState,
        open: false,
        selectedData: {},
        enteredAmount: ''
      };
    });
  };

  const handleAmountChange = e => {
    let value = e.target.value;
    setConfirmationDialog(prevState => {
      return {
        ...prevState,
        enteredAmount: value
      };
    });
  };

  const checkForDisable = ele => {
    if ((ele.type === 'stripe' && ele.token && ele.token.details_submitted === true) || (ele.type !== 'stripe' && ele.token)) {
      if (ele.amount <= 0) {
        return true;
      }
    }
    return false;


  };

  return (
    <>
      {props.linkedAccountData.length > 0 ? (
        <TableContainer
          component={Paper}
          style={{ maxHeight: 450, minHeight: 100 }}
        >
          <Table aria-label="collapsible table" stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Network Name</TableCell>
                <TableCell>Connect Status </TableCell>
                <TableCell>Total Amount</TableCell>
                <TableCell>Amount on Hold</TableCell>
                <TableCell>Amount Available</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>

            <TableBody className={classes.tableBody}>
              {props.linkedAccountData &&
                props.linkedAccountData.map((ele, index) => {
                  return (
                    <TableRow key={index} className={classes.root}>
                      <TableCell align="left">{ele.account_name}</TableCell>
                      <TableCell
                        align="left"
                        className={
                          (ele.type === 'stripe' && ele.token && ele.token.details_submitted === true) || (ele.type !== 'stripe' && ele.token)
                            ? classes.connectedColor
                            : classes.pendingColor
                        }
                      >
                        {(ele.type === 'stripe' && ele.token && ele.token.details_submitted === true) || (ele.type !== 'stripe' && ele.token) ? 'Connected' : 'Pending'}
                      </TableCell>
                      <TableCell align="left">-</TableCell>
                      <TableCell align="left">
                        {convertNegativeCurrency(
                          ele.amount_on_hold,
                          CURRENCY_SYMBOL[ele.asset_type_unit.toUpperCase()]
                        )}
                      </TableCell>
                      <TableCell align="left">
                        {convertNegativeCurrency(
                          ele.amount,
                          CURRENCY_SYMBOL[ele.asset_type_unit.toUpperCase()]
                        )}
                      </TableCell>
                      <TableCell>
                        <Button
                          onClick={() =>
                            ele.asset_type_unit === 'inr'
                              ? handleRazorPayAction(ele)
                              : handleAction(ele)
                          }
                          className={classes.actionBtn}
                          disabled={checkForDisable(ele)}
                        >
                          {(ele.type === 'stripe' && ele.token && ele.token.details_submitted === true) || (ele.type !== 'stripe' && ele.token) ? 'Withdraw' : 'Connect'}
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <EmptyState
          errorMessage="No network details available"
          height={'30vh'}
        />
      )}
      <ConfirmationDialog
        open={confirmationDialog.open}
        title={confirmationDialog.title}
        description={confirmationDialog.description}
        onConfirm={handleConfirmAction}
        onClose={closeConfirmationDialog}
        onCancel={closeConfirmationDialog}
      />
      <ConfirmationDialog
        open={transactionSuccess.open}
        title={transactionSuccess.title}
        description={transactionSuccess.description}
        onConfirm={handleSuccessPopup}
        hideCancel={true}
      />
    </>
  );
}

export default withRouter(NetworkTable);
