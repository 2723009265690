import axios from 'axios';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import jwt_decode from 'jwt-decode';
import { parsePhoneNumberWithError } from 'libphonenumber-js';
import * as _ from 'lodash';
import React, { useEffect, useRef } from 'react';
import { toaster } from './components/atoms/toaster';
import { EventsBaseClient } from './components/helpers/eventsBaseClient';
import {
  API_BASE_URL,
  SHOPTYPE_ADMIN_USER_ID,
  SHOPTYPE_API_KEY
} from './config/env';
import {
  COSELLER,
  CURRENCY_SYMBOL,
  GLOBAL_EVENT_KEYS,
  VENDOR
} from './constants';
import history from './history';
import * as actions from './redux/actions/uiActions';
import moment from 'moment';

export const ONLY_POSITIVE_INTEGERS = /^\d+$/;

export const ONLY_POSITIVE_FLOAT = /^[1-9][0-9]?(\.[0-9]{0,2})?$/;

export const PASSWORD_PATTERN_REGEX = /^.{6,}$/;

export const PASSWORD_PATTERN = '^.{6,}$';

export const URL_PATTERN_REGEX = /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i;
export const isUrlValid = url => {
  return URL_PATTERN_REGEX.test(url);
};

export const validateSocialMediaURL = url => {
  if (!url) return false;
  const socialMediaUrlRegex = /^(?:(?:https?:\/\/)?(?:www\.)?(?:twitter\.com|instagram\.com|facebook\.com|linkedin\.com|youtube\.com)\/(?:[a-zA-Z0-9_]+\/?)*(?:[a-zA-Z0-9_]+)?)$/i;
  return socialMediaUrlRegex.test(url);
};

export const containsAnyLetter = str => {
  return /[a-zA-Z]/.test(str);
};

export const isPasswordValid = password => {
  return PASSWORD_PATTERN_REGEX.test(password);
};

export const isEmailValid = email => {
  const emailPattern = /^[-!#$%&'*+\/0-9=?A-Z^_a-z{|}~](\.?[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;
  if (!emailPattern.test(email)) {
    return false;
  }

  const [localPart, domain] = email.split('@');
  if (localPart.length > 64) {
    return false;
  }

  const domainParts = domain.split('.');
  return !domainParts.some(part => part.length > 63);
};

export const isPhoneValid = phone => {
  try {
    const phoneNumber = parsePhoneNumberWithError(phone);
    return phoneNumber.isValid();
  } catch (error) {
    console.error(error);
  }

  return false;
};

export const isEnitityNameValid = name => {
  const namePattern = /^[a-zA-Z\s0-9-_]+$/;
  return namePattern.test(name);
};

export const isUserNameValid = name => {
  const namePattern = /^[a-zA-Z\s]+$/;
  return namePattern.test(name);
};

export const isFullNameValid = name => {
  const namePattern = /^([a-zA-Z0-9]+\s)*[a-zA-Z0-9]+$/;
  return namePattern.test(name);
};

export const isPositiveInteger = num => {
  return ONLY_POSITIVE_INTEGERS.test(num);
};

export const isPositiveFloat = num => {
  return ONLY_POSITIVE_FLOAT.test(num);
};

export const LEVEL_INTRO = [
  {
    name: 'Introduction Payout',
    shortName: 'lIntro',
    splitValue: '',
    expiry: 'Infinite',
    option: 'Infinite',
    timeLimit: null
  }
];

export const LEVELX = [
  {
    name: 'Level X',
    shortName: 'lX',
    splitValue: '',
    expiry: '1',
    option: 'Hours',
    timeLimit: 3600000000000
  }
];

export const successStatusCodes = [200, 202, 204, 201];
export const resourceNotFoundStatusCode = 404;

export const handleError = error => {
  if (error.response) {
    return error.response;
  }

  return error;
};

export const getUserType = () => {
  const userType = localStorage.getItem('USER_TYPE');
  if (!userType) return undefined;
  return userType;
};

export const getSelectedCurrency = () => {
  const selectedCurrency = localStorage.getItem('selectedCurrency');
  if (!selectedCurrency) return 'USD'; //returning USD as default
  return selectedCurrency;
};

export const getAuthId = () => {
  const id = JSON.parse(localStorage.getItem('_id'));
  if (!id) return undefined;
  return id;
};

export const getAuthToken = () => {
  const token = JSON.parse(localStorage.getItem('token'));
  if (!token) return undefined;
  return token;
};

export const getUserProfile = () => {
  const userProfile = JSON.parse(localStorage.getItem('userProfile'));
  if (!userProfile) return undefined;
  return userProfile;
};

export const getUserProfileNetworks = () => {
  const userProfileNetworks = JSON.parse(
    localStorage.getItem('userProfileNetworks')
  );
  if (!userProfileNetworks) return undefined;
  return userProfileNetworks;
};

export const setCartId = cartId => {
  localStorage.setItem('cartId', cartId);
};

export const getCartId = () => {
  const cartId = localStorage.getItem('cartId');
  if (!cartId) return undefined;
  return cartId;
};

export const setCheckoutId = checkoutId => {
  localStorage.setItem('checkoutId', checkoutId);
};

export const getCheckoutId = () => {
  const checkoutId = localStorage.getItem('checkoutId');
  if (!checkoutId) return undefined;
  return checkoutId;
};

export const removeCartId = () => {
  localStorage.removeItem('cartId');
};

export const removeCheckoutId = () => {
  localStorage.removeItem('checkoutId');
};

export const setCountriesList = countriesList => {
  localStorage.setItem('countriesList', JSON.stringify(countriesList));
};

export const getCountriesList = () => {
  const countriesList = JSON.parse(localStorage.getItem('countriesList'));
  if (!countriesList) return undefined;
  return countriesList;
};

export const setStatesList = statesList => {
  localStorage.setItem('statesList', JSON.stringify(statesList));
};

export const getStatesList = () => {
  const statesLlist = JSON.parse(localStorage.getItem('statesList'));
  if (!statesLlist) return undefined;
  return statesLlist;
};

export const setShippingInfo = data => {
  localStorage.setItem('shippingInfo', JSON.stringify(data));
};

export const getShippingInfo = () => {
  const shippingInfo = JSON.parse(localStorage.getItem('shippingInfo'));
  if (!shippingInfo) return undefined;
  return shippingInfo;
};

export const setBillingInfo = data => {
  localStorage.setItem('billingInfo', JSON.stringify(data));
};

export const getBilllingInfo = () => {
  const billingInfo = JSON.parse(localStorage.getItem('billingInfo'));
  if (!billingInfo) return undefined;
  return billingInfo;
};

export const getCosellerToken = () => {
  const token = JSON.parse(localStorage.getItem('cosellerToken'));
  if (!token) return undefined;
  return token;
};

export const getApi = async (url, headers = {}, handleErrors = true) => {
  try {
    const response = await axios.get(url, { headers });
    return response.data;
  } catch (error) {
    if (handleErrors) handleError(error);
  }
};

export const getApiResponseObject = async (
  url,
  headers = {},
  params = {},
  handleErrors = true
) => {
  try {
    const response = await axios
      .get(url, { headers: headers, params: params })
      .catch(function(error) {
        if (error.response) {
          return error.response;
        } else if (error.request) {
          return error.request;
        } else {
          return error;
        }
      });
    return response;
  } catch (error) {
    if (handleErrors) handleError(error);
  }
};

export const post = async (url, body, headers = null, handleErrors = true) => {
  try {
    if (!headers) {
      headers = { 'Content-type': 'application/json' };
    }
    const response = await axios.post(url, body, { headers });
    return response.data;
  } catch (error) {
    if (handleErrors) handleError(error);
    throw Error('error occurs');
  }
};

export const postWithResponseObject = async (
  url,
  body,
  headers = null,
  handleErrors = true
) => {
  try {
    if (!headers) {
      headers = { 'Content-type': 'application/json' };
    }
    const response = await axios
      .post(url, body, { headers })
      .catch(function(error) {
        if (error.response) {
          return error.response;
        } else if (error.request) {
          return error.request;
        } else {
          return error;
        }
      });
    return response;
  } catch (error) {
    if (handleErrors) handleError(error);
  }
};

export const deleteWithResponseObject = async (
  url,
  headers = null,
  body = {},
  handleErrors = true
) => {
  try {
    if (!headers) {
      headers = { 'Content-type': 'application/json' };
    }
    const response = await axios
      .delete(url, { headers }, body)
      .catch(function(error) {
        if (error.response) {
          return error.response;
        } else if (error.request) {
          return error.request;
        } else {
          return error;
        }
      });
    return response;
  } catch (error) {
    if (handleErrors) handleError(error);
  }
};

export const put = async (url, body, headers = null, handleErrors = true) => {
  try {
    if (!headers) {
      headers = { 'Content-type': 'application/json' };
    }
    const response = await axios.put(url, body, { headers });
    return response.data;
  } catch (error) {
    if (handleErrors) handleError(error);
    return toaster('error occurs');
  }
};

export const putWithResponseObject = async (
  url,
  body,
  headers = null,
  handleErrors = true
) => {
  try {
    if (!headers) {
      headers = { 'Content-type': 'application/json' };
    }
    const response = await axios
      .put(url, body, { headers })
      .catch(function(error) {
        if (error.response) {
          return error.response;
        } else if (error.request) {
          return error.request;
        } else {
          return error;
        }
      });
    return response;
  } catch (error) {
    if (handleErrors) handleError(error);
  }
};

export const sanitizeProductCategoryValue = categories => {
  return categories ? _.compact(_.map(categories, c => c.trim())) : [];
};

export const checkBrowserStoredData = () => {
  const id = JSON.parse(localStorage.getItem('_id'));
  const token = JSON.parse(localStorage.getItem('token'));
  const userProfile = JSON.parse(localStorage.getItem('userProfile'));
  const userType = localStorage.getItem('USER_TYPE');

  return id && token && userProfile && userType;
};

export const getBrowserStoredData = () => {
  const id = JSON.parse(localStorage.getItem('_id'));
  const token = JSON.parse(localStorage.getItem('token'));
  const userProfile = JSON.parse(localStorage.getItem('userProfile'));
  const userType = localStorage.getItem('USER_TYPE');

  return { id, token, userProfile, userType };
};

export const clearBrowserStoredData = () => {
  localStorage.removeItem('_id');
  localStorage.removeItem('token');
  localStorage.removeItem('userProfile');
  localStorage.removeItem('userProfileNetworks');
  localStorage.removeItem('USER_TYPE');
  localStorage.removeItem('locationInfo');
  localStorage.removeItem('selectedCurrency');
};

export const clearId = () => {
  localStorage.removeItem('_id');
};

export const isLoggedIn = () => {
  let urlString = window.location;
  let url = new URL(urlString);
  let access_token = new URLSearchParams(url.search).get('access_token');
  if (access_token) {
    localStorage.setItem('token', JSON.stringify(access_token));
    return access_token;
  } else {
    return getAuthToken();
  }
};

export const isShoptypeAdmin = () => {
  const token = localStorage.getItem('token');

  if (token) {
    const decoded = jwt_decode(token);
    return decoded.userId && decoded.userId === SHOPTYPE_ADMIN_USER_ID
      ? true
      : false;
  }
  return false;
};

export const isNoShoptypeCheckout = () => {
  try {
    const userProfile = JSON.parse(localStorage.getItem('userProfile'));
    if (!userProfile) {
      return checkUserProfile();
    } else {
      let enableValue = userProfile?.vendors[0]?.enableCheckoutShoptype;
      return (
        (enableValue == undefined || enableValue == false) &&
        userProfile.vendors[0].store.currency
      );
    }
  } catch (err) {
    console.error(err);
    return false;
  }
};

const checkUserProfile = async () => {
  let params = new URLSearchParams(document.location.search);
  let token = params.get('access_token');
  const profileRes = await getApiResponseObject(`${API_BASE_URL}/me`, {
    authorization: token
  });

  if (successStatusCodes.includes(profileRes.status)) {
    const userProfile = JSON.parse(localStorage.getItem('userProfile'));

    localStorage.setItem('userProfile', JSON.stringify(profileRes.data));
    EventsBaseClient.emit(GLOBAL_EVENT_KEYS.USER_PROFILE_DATA_CHANGE_EVENT);
    EventsBaseClient.emit(GLOBAL_EVENT_KEYS.UPDATE_SIDEBAR_CATEGORIES);
    return userProfile &&
      userProfile.vendors[0].store.currency &&
      !userProfile.vendors[0].enableCheckoutShoptype
      ? true
      : false;
  }
};

export const addLogoutListener = () => {
  window.addEventListener('storage', handleStorageLogoutEvent);
};

export const removeLogoutListener = () => {
  window.removeEventListener('storage', handleStorageLogoutEvent);
};

export const handleStorageLogoutEvent = e => {
  if (e.key === 'token' && e.oldValue && !e.newValue) {
    logout();
  }
};

export const logout = () => {
  clearBrowserStoredData();
  history.push('/');
  EventsBaseClient.emit(GLOBAL_EVENT_KEYS.TOGGLE_NAVBAR_AND_SIDEBAR);
};

export const isInvalidVendorDetails = (vendorName, productCategories) => {
  if (!(vendorName && isEnitityNameValid(vendorName))) {
    return 'Please enter a valid vendor name.';
  }

  if (!productCategories) {
    return 'Please select a valid product category';
  }

  return null;
};

export const fetchPlatformDetails = userType => {
  switch (userType) {
    case 'NETWORK_OPERATOR':
      history.push('/networkoperator/dashboard');
      break;
    case 'VENDOR':
      history.push('/dashboard');
      break;
    case 'COSELLER':
      history.push('/cosellerdashboard');
      break;
    default:
      history.push('/users/platform');
  }
};

export const isInvalidNetoworkDetails = vendorName => {
  if (!(vendorName && isEnitityNameValid(vendorName))) {
    return 'Please enter a valid vendor name.';
  }
  return null;
};

export const formHashTag = input => {
  return (input && `#${input.replace(/[^a-z0-9.]+/gi, '')}`) || '';
};

const f = (a, b) => [].concat(...a.map(d => b.map(e => [].concat(d, e))));
export const cartesian = (a, b, ...c) => (b ? cartesian(f(a, b), ...c) : a);

export const disableHomeRedirect = path => {
  const routes = [
    '/users/signin',
    '/users/signin',
    '/users/onboard',
    '/users/onboard/sociallogin',
    '/users/onboard/interests'
  ];

  return routes.includes(path);
};

export const getCookieStorage = cname => {
  let name = cname + '=';
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for (const element of ca) {
    let c = element;
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }

  return '';
};

export const addQueryParamsToUrl = (link, queryParamsObject) => {
  let url = new URL(link);
  Object.keys(queryParamsObject).forEach(queryKey =>
    url.searchParams.append(queryKey, queryParamsObject[queryKey])
  );
  return url.href;
};

export const setQueryParamsToUrl = (link, queryParamsObject) => {
  let url = new URL(link);
  Object.keys(queryParamsObject).forEach(queryKey => {
    url.searchParams.set(queryKey, queryParamsObject[queryKey]);
  });
  return url.href;
};

export const getRouteUserType = url => {
  return url.toLowerCase().indexOf('vendor') !== -1 ? VENDOR : COSELLER;
};

export const cookieEnabled = () => {
  return window.navigator.cookieEnabled;
};

export const getAuthHeaders = () => {
  return {
    'Content-type': 'application/json',
    'X-Shoptype-Api-Key': SHOPTYPE_API_KEY
  };
};

export const capitalize = str => {
  return str && str.length > 0 ? str[0].toUpperCase() + str.slice(1) : '';
};

export const titleCase = str => {
  return str && str.length > 0 ? _.startCase(_.camelCase(str)) : '';
};

export const snakeCase = str => {
  return str && str.length > 0
    ? str
        .trim()
        .replaceAll(' ', '_')
        .toLowerCase()
    : '';
};

export const toBoolean = str => {
  return str && str.length > 0 && str === 'true';
};

export const binaryToAscii = data => {
  try {
    return window.btoa(unescape(encodeURIComponent(JSON.stringify(data))));
  } catch (err) {
    console.error(err);
  }
};

export const isStateBase64Valid = state => {
  try {
    const decoded = asciiToBinary(state);
    return decoded.user_id &&
      decoded.account_id &&
      decoded.currency &&
      decoded.tab
      ? true
      : false;
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const asciiToBinary = str => {
  try {
    return JSON.parse(window.atob(unescape(decodeURIComponent(str))));
  } catch (err) {
    console.error(err);
  }
};

export const currencySymbol = currency => {
  try {
    if (CURRENCY_SYMBOL[currency]) {
      return CURRENCY_SYMBOL[currency];
    } else {
      return CURRENCY_SYMBOL.USD;
    }
  } catch (err) {
    console.error(err);
  }
};

export const getApiErrorMessage = errorObj => {
  try {
    return errorObj.message || errorObj.msg || errorObj.error || null;
  } catch (err) {
    console.error(err);
    return null;
  }
};

export const showApiErrorToast = errorObj => {
  try {
    toaster(
      errorObj.message ||
        errorObj.msg ||
        errorObj.error ||
        'Something went wrong!'
    );
  } catch (err) {
    console.error(err);
    toaster('Something went wrong!');
  }
};

export const showApiSuccessToast = resObj => {
  try {
    toaster(resObj.message || resObj.msg || 'Success!');
  } catch (err) {
    console.error(err);
    toaster('Success!');
  }
};

export const isMobile = () => {
  let check = false;
  (function(a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4)
      )
    )
      check = true;
  })(navigator.userAgent || navigator.vendor || window.opera);
  return check;
};

export const exportPDF = (title, headers, keys, pdfData) => {
  const unit = 'pt';
  const size = 'A4'; // Use A1, A2, A3 or A4
  const orientation = 'portrait'; // portrait or landscape

  const marginLeft = 40;
  const doc = new jsPDF(orientation, unit, size);

  doc.setFontSize(15);
  const data = pdfData.map(elt => {
    let rowData = [];
    keys.forEach(key => rowData.push(elt[key]));
    return rowData;
  });

  let content = {
    startY: 50,
    head: headers,
    body: data
  };

  doc.text(title, marginLeft, 40);
  doc.autoTable(content);
  doc.save(`${title}.pdf`);
};

export const uploadImageVideoHandler = (
  e,
  postProfilePic,
  dispatch,
  feature = null
) => {
  const selectedFile = e.target.files ? e.target.files[0] : '';
  const selectedFileName = e.target.files ? e.target.files[0].name : '';
  const fileSizeInBytes = e.target.files[0].size || '';
  const mediaFileUploadLimit = { default: 10 };

  let newAttributes = {
    profilePicture: ''
  };

  if (fileSizeInBytes / 1000000 > mediaFileUploadLimit.default) {
    toaster('Please upload files of size below 10 mb');
  } else {
    let uploadMediaRequestBody = new FormData();
    uploadMediaRequestBody.append(selectedFileName, selectedFile);
    uploadMediaRequestBody.append('fileNames', `["${selectedFileName}"]`);

    const token = getAuthToken();
    const headers = {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: token
    };
    dispatch(actions.showSpinnerBackdropAction());

    post(
      `${API_BASE_URL}/command?type=addMedia`,
      uploadMediaRequestBody,
      headers
    ).then(response => {
      const fetchedUploadedMediaUrl = response[Object.keys(response)[0]];
      dispatch(actions.hideSpinnerBackdropAction());
      if (feature == 'profilePic') {
        newAttributes.profilePicture = fetchedUploadedMediaUrl;
        postProfilePic(newAttributes);
      } else {
        postProfilePic(fetchedUploadedMediaUrl);
      }
    });
  }
};

export const socialLinksHandler = socialLink => {
  if (socialLink) {
    return socialLink;
  } else {
    return '';
  }
};

export const convertNegativeCurrency = (amount, currency) => {
  try {
    if (amount < 0) {
      return `-${currency}${Math.abs(amount)}`;
    } else {
      return `${currency}${Math.abs(amount)}`;
    }
  } catch (err) {
    console.error(err);
  }
};

export const fetchAttributionLevels = (attributionObj, levels) => {
  for (let i = 0; i < levels; i++) {
    if (!attributionObj.attributionConfig.configs[`l${10 - i}`]) {
      attributionObj.attributionConfig.configs[`l${10 - i}`] = {
        percentage: '',
        timeLimit: '',
        timeLimitUnit: 'hours'
      };
    }
  }
  for (let i = 9; i >= levels; i--) {
    if (attributionObj.attributionConfig.configs[`l${10 - i}`]) {
      delete attributionObj.attributionConfig.configs[`l${10 - i}`];
    }
  }
  return attributionObj;
};

// Detecting an outside click of a functional component (auto-close the dropped items)
export const DetectOutsideClick = props => {
  const ref = useRef(null);
  const { onClickOutside, component, show } = props;

  useEffect(() => {
    const handleClickOutside = event => {
      if (ref.current && !ref.current.contains(event.target)) {
        onClickOutside && onClickOutside();
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [onClickOutside]);

  if (!show) return null;

  return (
    <div ref={ref} className="info-box">
      {component}
    </div>
  );
};
/**
 * @function sortAlphaNumeric
 * @description Sort alphanumeric data based on order and key
 *
 * @param {Array.<string> || Array.<decimal> || Object[]} data List of data (array of strings, decimals or objects)
 * @param {String='ASC','DESC'} orderBy Order of data (Ascending or Descending)
 * @param {String} [key] Key/Property name of an object
 *
 * @return {Array} sortedData
 */
export const sortAlphaNumeric = (data, orderBy, key) => {
  const regexNonAlpha = /[^a-zA-Z]/g;
  const regexNumeric = /[+-]?([0-9]*[.])?[0-9]+/g;
  let order;

  switch (orderBy.toUpperCase()) {
    case 'ASC':
      order = 1;
      break;
    case 'DESC':
      order = -1;
      break;
    default:
      order = 1;
  }

  const sortedData = data.sort((a, b) => {
    const itemA = key ? a[key].toString() : a.toString();
    const itemB = key ? b[key].toString() : b.toString();
    const aAlpha = itemA.replace(regexNonAlpha, '');
    const bAlpha = itemB.replace(regexNonAlpha, '');

    if (aAlpha === bAlpha) {
      let aNum = itemA.match(regexNumeric);
      let bNum = itemB.match(regexNumeric);
      aNum = aNum ? parseFloat(aNum.join('')) : 0;
      bNum = bNum ? parseFloat(bNum.join('')) : 0;

      return aNum === bNum ? 0 : aNum < bNum ? -order : order;
    }
    return aAlpha < bAlpha ? -order : order;
  });

  return sortedData;
};
export const dateWithoutTime = date => {
  let dateWithoutTime = moment(date).format('YYYY[-]MM[-]DD');
  return dateWithoutTime;
};
